import React from "react";

function Loading() {
  return (
    <section className="main" id="loader-section">
      <div className="container">
        <img src="/assets/gptw-main-header.svg" alt="" />
        <div className="loader"></div>
        <img src="/assets/gptw-main-footer.svg" alt="" />
      </div>
    </section>
  );
}

export default Loading;
